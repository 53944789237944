import axios, { AxiosInstance } from "axios";


export class CartApi {
  
  protected readonly api: AxiosInstance = axios.create({
    responseType: "json",
    baseURL: "/public/shoppingcart-service/api/v1",
    headers: {
      "Content-Type": "application/json"
    }
  });

  public async createCart(payload: { cartId: string, token?: string }): Promise<{ status: number, data: any }> {

    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;

    const result: { status: number, data: any } = await this.api
      .post<any>(`/shoppingCarts`, {
        shoppingCartId: payload.cartId,
      }, {
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async getCart(payload: { cartId: string, token?: string }): Promise<{ status: number, data: any }> {

    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;
    
    const result: { status: number, data: any } = await this.api
      .get<any>(`/shoppingCarts/${ payload.cartId }`, {
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async addItem(payload: { cartId: string, item: any, searchToken?: string }):
    Promise<{ status: number, data: any }> {

    const headers: any = {};
    if (payload.searchToken) headers['Authorization'] = `Bearer ${payload.searchToken}`;
    
    const result: { status: number, data: any } = await this.api
      .post<any>(`/shoppingCarts/${ payload.cartId }/items`, payload.item, {
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async addItems(payload: { cartId: string, data: any, token?: string }): Promise<{ status: number, data: any }> {

    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;
    
    const result: { status: number, data: any } = await this.api
      .post<any>(`/shoppingCarts/${ payload.cartId }/items/v2`, payload.data, {
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async addPromocode(payload: { cartId: string, code: string, token?: string }): 
    Promise<{ status: number, data: any }> {

    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;
        
    const result: { status: number, data: any } = await this.api
      .post<any>(`/shoppingCarts/${ payload.cartId }/promotionalCodes`, { code: payload.code }, {
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: error.response.data };
        }
      });
    
    return result;
  }

  public async removeItem(payload: { cartId: string, cartItemId: string, token?: string }): 
    Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;

    const result: { status: number, data: any } = await this.api
      .delete<any>(`/shoppingCarts/${ payload.cartId }/items/${ payload.cartItemId }`, {
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async removeItemsById(payload: { cartId: string, items: string[], token?: string }): 
    Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;

    const result: { status: number, data: any } = await this.api
      .delete<any>(`/shoppingCarts/${ payload.cartId }/items`, {
        data: payload.items,
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async removePromocode(payload: { cartId: string, codeId: string, token?: string }): 
    Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;
      
    const result: { status: number, data: any } = await this.api
      .delete<any>(`/shoppingCarts/${ payload.cartId }/promotionalCodes/${ payload.codeId }`)
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async clearCart(payload: { cartId: string, token?: string }): 
    Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;
  
    const result: { status: number, data: any } = await this.api
      .delete<any>(`/shoppingCarts/${ payload.cartId }`, {
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async setCartAttributes(payload: { cartId: string, attributes: any[], token?: string }): 
    Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;
  
    const result: { status: number, data: any } = await this.api
      .patch<any>(`/shoppingCarts/${ payload.cartId }/attributes`, payload.attributes, {
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async setCustomer(payload: { cartId: string, customer: any, token?: string }): Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;

    const result: { status: number, data: any } = await this.api
      .put<any>(`/shoppingCarts/${ payload.cartId }/customer`, payload.customer, {
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async checkout(payload: { cartId: string, token?: string }): Promise<{ status: number, data: any }> {
    
    const headers: any = {};
    if (payload.token) headers['Authorization'] = `Bearer ${payload.token}`;

    const result: { status: number, data: any } = await this.api
      .post<any>(`/shoppingCarts/${ payload.cartId }`, {}, {
        headers,
      })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

}
