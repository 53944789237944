import axios, { AxiosInstance } from "axios";


export class InvoicesApi {
  
  protected readonly api: AxiosInstance = axios.create({
    responseType: "json",
    baseURL: "/public/invoice-service/api/v1",
    headers: {
      "Content-Type": "application/json"
    }
  });

  public async getPaymentTypes(payload: { amount: number, currencyId: number }): 
    Promise<{ status: number, data: any }> {
   
    const params: any = {
      amount: payload.amount,
      currencyId: payload.currencyId,
    }
    const result: { status: number, data: any } = await this.api
      .get<any>(`/paymentTypes`, { params: params })
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async createPayment(payload: { invoiceId: string, payment: any }): 
    Promise<{ status: number, data: any }> {
    
    const result: { status: number, data: any } = await this.api
      .post<any>(`/invoices/${ payload.invoiceId }/payments`, payload.payment)
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

  public async getPayments(payload: { invoiceId: string }): Promise<{ status: number, data: any }> {
    
    const result: { status: number, data: any } = await this.api
      .get<any>(`/invoices/${payload.invoiceId}/payments`)
      .then(response => {
        return { status: response.status, data: response.data };
      })
      .catch(error => {
        if (error.response === undefined) {
          // on network error
          return { status: 1001, data: null };
        } else {
          // on other errors
          return { status: error.response.status, data: null };
        }
      });
    
    return result;
  }

}
